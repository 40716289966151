import { Component, OnDestroy, OnInit, inject } from "@angular/core";
import { REQUEST, SsrCookieService } from "ngx-cookie-service-ssr";
import { blackList } from "./black-list";
import { TranslateModule } from "@ngx-translate/core";
import { ScreenSizeService } from "@app/shared/screen-size.service";

@Component({
  selector: "app-cookie-tool",
  templateUrl: "./cookie-tool.component.html",
  styleUrls: ["./cookie-tool.component.scss"],
  standalone: true,
  imports: [TranslateModule],
})
export class CookieToolComponent implements OnInit, OnDestroy {
  private cookieService = inject(SsrCookieService);
  private request = inject<Request>(REQUEST, { optional: true });

  screenSizeService = inject(ScreenSizeService);

  cookiesEnabled: boolean | undefined;
  cookieMonsterInterval: any;

  isBannerHidden: boolean = true;
  isReducedBannerHidden: boolean = true;

  constructor() {
    if (!this.screenSizeService.isBrowser) {
      throw new Error("CookieToolComponent is not supported on the server");
    }
  }

  ngOnInit() {
    let hostname: string;
    hostname = window.location.hostname;

    // disable on localhost
    if (hostname === "localhost") {
      this.asyncLoadingComplete();
      return;
    }

    if (this.screenSizeService.isBrowser) {
      this.initializeCookieControl();
    }
  }

  ngOnDestroy() {
    if (this.cookieMonsterInterval) {
      clearInterval(this.cookieMonsterInterval);
    }
  }

  initializeCookieControl() {
    const cookiesEnabled = this.checkCookiesEnabled();
    if (cookiesEnabled === true) {
      this.asyncLoadingComplete();
      this.isBannerHidden = true;
      this.isReducedBannerHidden = true;
      return;
    } else if (cookiesEnabled === false) {
      this.cookieMonster();
      return;
    } else {
      this.isBannerHidden = false;
      this.isReducedBannerHidden = true;
      this.cookieMonster();
    }
    setTimeout(() => this.shouldShowCookieBannerReduced(), 30000);
  }

  checkCookiesEnabled(): boolean | undefined {
    if (navigator.doNotTrack) return false;
    const acceptCookies = this.cookieService.get("acceptcookies");

    if (acceptCookies === "true") return true;
    if (acceptCookies === "false") return false;
    return undefined;
  }

  cookieMonster() {
    this.clearCookies();
    this.cookieMonsterInterval = setInterval(() => {
      this.clearCookies();
    }, 1000);
  }

  prevCookie = "";
  clearCookies() {
    const currentCookie = this.cookieService.getAll();
    if (JSON.stringify(currentCookie) !== this.prevCookie) {
      this.prevCookie = JSON.stringify(currentCookie);
      Object.keys(currentCookie).forEach((key) => {
        blackList.forEach((blackListItem) => {
          if (key.startsWith(blackListItem)) {
            this.cookieService.delete(key);
          }
        });
      });
    }
  }

  onAccept(): void {
    this.cookieService.set("acceptcookies", "true");
    this.isBannerHidden = true;
    clearInterval(this.cookieMonsterInterval);
    this.asyncLoadingComplete();
  }

  onReject(): void {
    this.cookieService.set("acceptcookies", "false");
    this.isBannerHidden = true;
    this.cookieMonster();
  }

  onClose(): void {
    this.isReducedBannerHidden = true;
  }

  shouldShowCookieBannerReduced(): void {
    if (!this.cookieService.get("acceptcookies")) {
      this.isReducedBannerHidden = false;
      this.isBannerHidden = true;
      this.onReject();
    }
  }

  asyncLoadingComplete() {}
}
