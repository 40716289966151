import { Component, computed, inject } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Book } from "src/app/models/shared-models/book.model";
import { CommonModule } from "@angular/common";
import { BookComponent } from "./book.component";
import { BookMissingComponent } from "./book.missing.component";

@Component({
  selector: "app-book-page",
  templateUrl: "./book-page.component.html",
  standalone: true,
  imports: [CommonModule, BookComponent, BookMissingComponent],
})
export class BookPageComponent {
  private route = inject(ActivatedRoute);

  book = computed(() => this.route.snapshot.data["book"] as Book | null);
  isbn = computed(() => this.route.snapshot.paramMap.get("isbn"));
}
