@if (collection) {
  <section id="open-access-publications">
    <div class="container max-width">
      <app-open-access-covers
        class="md-up"
        [products]="products"
      ></app-open-access-covers>
      <slider
        class="md-down"
        [slides]="products"
        [slidesPerView]="1"
        [breakpoints]="breakpoints"
        [showDots]="false"
        [loop]="false"
        [origin]="'auto'"
        [fullHeight]="false"
      ></slider>
      <h1 class="title" [innerHTML]="collection.wpItem.title"></h1>
      <h2
        class="description med-width balance"
        [innerHTML]="collection.wpItem.content"
      ></h2>
      <button
        class="large"
        [routerLink]="['/', lang.currentLanguage(), 'search']"
        [queryParams]="{
          tags: 'openAccess',
          sortBy: 'date',
          sortOrder: 'DESC',
        }"
      >
        {{ "OPEN-ACCESS-PUBLICATIONS.Action" | translate }}
      </button>
    </div>
  </section>
}
