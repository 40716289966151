import { inject } from "@angular/core";
import { ResolveFn } from "@angular/router";
import { NewsHomepageService } from "@app/services/news.homepage.service";
import { WordPressService } from "@app/services/word-press.service";
import { HomePageModule } from "src/app/models/home-page-module.model";
import { HomepageModulesService } from "src/app/services/homepage-modules.service";

export const homepageResolver: ResolveFn<HomePageModule[]> = async () => {
  const homepageModulesService = inject(HomepageModulesService);
  const newsHomepageService = inject(NewsHomepageService);

  await newsHomepageService.reload();
  const hpmodules = await homepageModulesService.fetchModules();
  return hpmodules;
};
