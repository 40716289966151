import {
  provideRouter,
  RouterModule,
  Routes,
  withComponentInputBinding,
  withEnabledBlockingInitialNavigation,
  withInMemoryScrolling,
  withRouterConfig,
} from "@angular/router";

import { redirectGuard } from "./core/guards/redirect.guard";
import { bookResolver } from "./pages/book/book.resolver";
import { newsResolver } from "./pages/news/news.resolver";

import { redirectEbooksGuard } from "./core/guards/redirect-ebooks.guard";
import { seriesRedirectGuard } from "./core/guards/series-redirect.guard";
import { bookNameHtmlRedirectGuard } from "./core/guards/book-name-html-redirect.guard";

import { SeriesListComponent } from "./pages/series/series-list/series-list.component";
import { homepageResolver } from "./pages/homepage/homepage.resolver";
import { homepageThematicCategoriesResolver } from "./pages/homepage/homepageThematicCategoriesResolver";
import { HomepageComponent } from "./pages/homepage/homepage.component";
import { languageGuard } from "./core/guards/language.guard";
import { RedirectComponent } from "./components/redirect/redirect.component";
import { BookPageComponent } from "./pages/book/book-page.component";

const jovisRoutes: Routes = [
  {
    path: "",
    component: HomepageComponent,
    resolve: {
      modules: homepageResolver,
      homepageThematicCategories: homepageThematicCategoriesResolver,
    },
  },
  {
    path: "ancillary-test",
    loadComponent: () =>
      import("./components/ancillary/ancillary.component").then(
        (m) => m.AncillaryComponent,
      ),
  },
  {
    path: "search",
    loadComponent: () =>
      import("./pages/search-page/search-page.component").then(
        (m) => m.SearchPageComponent,
      ),
  },
  { path: "search.html", redirectTo: "search" }, // redirect from old site
  {
    path: "books",
    loadComponent: () =>
      import("./pages/search-page/search-page.component").then(
        (m) => m.SearchPageComponent,
      ),
  }, // redirect from old site
  { path: "books.html", redirectTo: "books" }, // redirect from old site
  {
    path: "books/:path",
    loadComponent: () =>
      import("./components/redirect/redirect.component").then(
        (m) => m.RedirectComponent,
      ),
    canActivate: [bookNameHtmlRedirectGuard],
  },
  {
    path: "buecher",
    loadComponent: () =>
      import("./pages/search-page/search-page.component").then(
        (m) => m.SearchPageComponent,
      ),
  },
  { path: "buecher.html", redirectTo: "buecher" }, // redirect from old site
  {
    path: "buecher/:path",
    loadComponent: () =>
      import("./components/redirect/redirect.component").then(
        (m) => m.RedirectComponent,
      ),
    canActivate: [bookNameHtmlRedirectGuard],
  },
  {
    // redirect from old site
    path: "e-books",
    loadComponent: () =>
      import("./components/redirect/redirect.component").then(
        (m) => m.RedirectComponent,
      ),
    canActivate: [redirectEbooksGuard],
    pathMatch: "full",
  },
  {
    // redirect from old site
    path: "e-books.html",
    loadComponent: () =>
      import("./components/redirect/redirect.component").then(
        (m) => m.RedirectComponent,
      ),
    canActivate: [redirectEbooksGuard],
    pathMatch: "full",
  },
  {
    path: "news",
    loadComponent: () =>
      import("./pages/news/news-page/news.component").then(
        (m) => m.NewsComponent,
      ),
    resolve: {
      news: newsResolver,
    },
    runGuardsAndResolvers: "always",
  },
  { path: "news.html", redirectTo: "news" },
  { path: "events/**", redirectTo: "news" }, // redirect from old site
  { path: "events.html", redirectTo: "news" }, // redirect from old site
  {
    path: "news/:id",
    loadComponent: () =>
      import("./pages/news/news-item.component").then(
        (m) => m.NewsItemComponent,
      ),
  },
  {
    path: "series",
    loadComponent: () =>
      import("./components/redirect/redirect.component").then(
        (m) => m.RedirectComponent,
      ),
    canActivate: [seriesRedirectGuard],
  },
  {
    path: "series/:key",
    loadComponent: () =>
      import("./pages/series/series.component").then((m) => m.SeriesComponent),
  },
  {
    path: "collection/:key",
    loadComponent: () =>
      import("./pages/collection/collection.component").then(
        (m) => m.CollectionComponent,
      ),
  },
  {
    path: "collections",
    loadComponent: () =>
      import("./pages/collections/collections.component").then(
        (m) => m.CollectionsComponent,
      ),
  },
  {
    path: "book/:isbn",
    component: BookPageComponent,
    resolve: {
      book: bookResolver,
    },
    runGuardsAndResolvers: "always",
  },
  {
    path: "s",
    loadComponent: () =>
      import("./pages/homepage/homepage-series/homepage-series.component").then(
        (m) => m.HomepageSeriesComponent,
      ),
  },
  {
    path: "cart",
    loadChildren: () =>
      import("./pages/shopping-cart/shopping-cart.module").then(
        (m) => m.ShoppingCartModule,
      ),
  },
  {
    path: "publisher",
    loadChildren: () =>
      import("./pages/publisher/publisher.module").then(
        (m) => m.PublisherModule,
      ),
  },
  {
    path: "verlag",
    loadChildren: () =>
      import("./pages/publisher/publisher.module").then(
        (m) => m.PublisherModule,
      ),
  },
  {
    path: "impressum",
    loadComponent: () =>
      import("./pages/three-columns/three-columns.component").then(
        (m) => m.ThreeColumnsComponent,
      ),
    data: { slug: "imprint" },
  },
  {
    path: "imprint",
    loadComponent: () =>
      import("./pages/three-columns/three-columns.component").then(
        (m) => m.ThreeColumnsComponent,
      ),
    data: { slug: "imprint" },
  },
  {
    path: "datenschutz",
    loadComponent: () =>
      import("./pages/three-columns/three-columns.component").then(
        (m) => m.ThreeColumnsComponent,
      ),
    data: { slug: "datenschutz" },
  },
  {
    path: "privacy-policy",
    loadComponent: () =>
      import("./pages/three-columns/three-columns.component").then(
        (m) => m.ThreeColumnsComponent,
      ),
    data: { slug: "privacy-policy" },
  },
  {
    path: "cookie-policy",
    loadComponent: () =>
      import("./pages/cookie-policy/cookie-policy.component").then(
        (m) => m.CookiePolicyComponent,
      ),
    data: { slug: "cookie-policy" },
  },
  {
    path: "cookie-richtlinien",
    loadComponent: () =>
      import("./pages/cookie-policy/cookie-policy.component").then(
        (m) => m.CookiePolicyComponent,
      ),
    data: { slug: "cookie-policy" },
  },
  {
    path: "terms-and-conditions",
    loadComponent: () =>
      import("./pages/three-columns/three-columns.component").then(
        (m) => m.ThreeColumnsComponent,
      ),
    data: { slug: "terms-conditions" },
  },
  {
    path: "agb",
    loadComponent: () =>
      import("./pages/three-columns/three-columns.component").then(
        (m) => m.ThreeColumnsComponent,
      ),
    data: { slug: "terms-conditions" },
  },
  { path: "**", redirectTo: "search" },
];

const routes: Routes = [
  {
    path: "",
    pathMatch: "full",
    canActivate: [languageGuard],
    component: RedirectComponent,
  },
  {
    path: "en",
    canActivate: [languageGuard],
    children: jovisRoutes,
  },
  {
    path: "de",
    canActivate: [languageGuard],
    children: jovisRoutes,
  },
  {
    path: "**",
    canActivate: [redirectGuard],
    component: RedirectComponent,
  },
];

export const routerProvider = provideRouter(
  routes,
  withEnabledBlockingInitialNavigation(),
  withComponentInputBinding(),
  withInMemoryScrolling({
    anchorScrolling: "enabled",
    scrollPositionRestoration: "enabled",
  }),
  withRouterConfig({
    onSameUrlNavigation: "reload",
    paramsInheritanceStrategy: "always",
  }),
);
