@if (show) {
  <div class="overlay" (click)="onClose()">
    <div class="popup" (click)="$event.stopPropagation()">
      <div
        class="close pointer"
        (click)="onClose()"
        inlineSVG="img/icon-x.svg"
      ></div>
      <div class="header">
        <h3 class="title">
          {{ titleKey | translate }}
          @if (icon) {
            <div [inlineSVG]="icon"></div>
          }
        </h3>
      </div>
      <ng-content></ng-content>
    </div>
  </div>
}
