import { Component, inject } from "@angular/core";
import { LanguageService } from "src/app/core/services/language.service";
import { ShoppingCartService } from "src/app/services/shopping-cart.service";
import { NavigationService } from "src/app/shared/navigation.service";
import { environment } from "src/environments/environment";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
  standalone: true,
  imports: [CommonModule, RouterModule, TranslateModule],
})
export class FooterComponent {
  languageService = inject(LanguageService);
  shoppingCartService = inject(ShoppingCartService);
  nav = inject(NavigationService);
  year = new Date().getFullYear();

  constructor() {}

  supportedCurrencies = environment.supportedCurrencies;
}
