<nav
  [ngStyle]="{
    backgroundColor: backgroundColor,
    color: textColor,
  }"
>
  <div class="nav-container">
    <div class="container max-width">
      <div class="left" gaCategory="header_nav">
        <a
          gaEvent="header_nav_books"
          [routerLink]="['/', currentLang, 'books']"
          routerLinkActive="active"
          class="pointer"
          draggable="false"
          (click)="openBooksPage()"
          >{{ "GLOBALS.Books" | translate }}</a
        >
        <a
          gaEvent="header_nav_books"
          [routerLink]="['/', currentLang, 'news']"
          routerLinkActive="active"
          class="pointer"
          draggable="false"
          >{{ "GLOBALS.News" | translate }}</a
        >
        <a
          gaEvent="header_nav_publisher"
          [routerLink]="['/', currentLang, 'publisher']"
          routerLinkActive="active"
          class="pointer"
          draggable="false"
          >{{ "GLOBALS.Publisher" | translate }}</a
        >
      </div>
      <a
        gaEvent="header_nav_home"
        class="pointer"
        draggable="false"
        id="logo"
        [routerLink]="['/', currentLang]"
        inlineSVG="img/jovis-logo.svg"
      ></a>
      <div class="right">
        <div
          gaEvent="header_nav_search"
          class="search pointer"
          inlineSVG="img/icon-search.svg"
          (click)="goToSearch(true)"
        ></div>
        <div
          gaEvent="header_nav_cart"
          draggable="false"
          [routerLink]="['/', currentLang, 'cart']"
          class="cart pointer"
        >
          <div inlineSVG="img/icon-cart.svg"></div>
          @if (cart.itemsCount()) {
            <div class="count">
              {{ cart.itemsCount() }}
            </div>
          }
        </div>
        <!-- <div
              id="language-switcher"
              (click)="switchLanguage()"
              class="pointer md-up"
              >
              <span [class.active]="languageService.isCurrentLanguage('de')"
                >DE</span
                >
                <span [class.active]="languageService.isCurrentLanguage('en')"
                  >EN</span
                  >
                </div> -->
        <div class="burger pointer">
          <div
            draggable="false"
            (click)="openBurgerMenu()"
            inlineSVG="img/icon-burger.svg"
          ></div>
          <ng-container [ngTemplateOutlet]="burgerMenu"></ng-container>
        </div>
      </div>
    </div>
  </div>
</nav>

<ng-template #burgerMenu>
  <div
    id="burger-menu"
    [style.display]="showBurgerMenu ? 'block' : 'none'"
    [@slideInOut]="showBurgerMenu ? 'in' : 'out'"
    [navbarColor]="{ background: '#e6e2da', text: '#181818' }"
    gaCategory="burger_menu"
  >
    <div class="row">
      <a
        class="pointer"
        draggable="false"
        id="logo"
        gaEvent="burger_nav_logo"
        [routerLink]="['/', currentLang]"
        inlineSVG="img/jovis-logo.svg"
        (click)="showBurgerMenu = false"
      ></a>
      <div class="close pointer" (click)="closeBurgerMenu()">
        <span inlineSVG="img/icon-x.svg"></span>
      </div>
    </div>

    <div class="group">
      <a
        gaEvent="burger_nav_books"
        class="pointer"
        draggable="false"
        (click)="goToSearch(false)"
        >{{ "GLOBALS.Books" | translate }}</a
      >
      <a
        gaEvent="burger_nav_news"
        [routerLink]="['/', currentLang, 'news']"
        class="pointer"
        draggable="false"
        (click)="showBurgerMenu = false"
        >{{ "GLOBALS.News" | translate }}</a
      >
      <a
        gaEvent="burger_nav_publisher"
        [routerLink]="['/', currentLang, 'publisher']"
        class="pointer"
        draggable="false"
        (click)="showBurgerMenu = false"
        >{{ "GLOBALS.Publisher" | translate }}</a
      >
    </div>

    <div class="group">
      <a
        gaEvent="burger_nav_search"
        class="pointer"
        draggable="false"
        (click)="goToSearch(true)"
        >{{ "GLOBALS.Search" | translate }}</a
      >
      <a
        gaEvent="burger_nav_cart"
        [routerLink]="['/', currentLang, 'cart']"
        class="pointer"
        draggable="false"
        (click)="showBurgerMenu = false"
        >{{ "GLOBALS.Cart" | translate }}</a
      >
    </div>

    <div class="bottom section mono">
      <div
        id="language-switcher"
        (click)="languageService.switchLanguage()"
        class="pointer"
        gaEvent="burger_switch_language"
      >
        <span [class.active]="languageService.isCurrentLanguage('de')">DE</span>
        <span [class.active]="languageService.isCurrentLanguage('en')">EN</span>
      </div>

      <div id="social-media" class="section">
        <a
          gaEvent="burger_nav_newsletter"
          (click)="showBurgerMenu = false"
          draggable="false"
          [routerLink]="['/', currentLang, 'publisher', 'newsletter']"
          >{{ "GLOBALS.Newsletter" | translate }}</a
        >
        <a
          gaEvent="burger_nav_facebook"
          draggable="false"
          target="_blank"
          [href]="nav.getFacebookLink()"
          >Facebook</a
        >
        <a
          gaEvent="burger_nav_instagram"
          draggable="false"
          target="_blank"
          [href]="nav.getInstagramLink()"
          >Instagram</a
        >
      </div>

      <div class="section">
        <a
          gaEvent="burger_nav_imprint"
          (click)="showBurgerMenu = false"
          draggable="false"
          [routerLink]="['/', currentLang, 'imprint']"
          class="pointer"
          >{{ "FOOTER.Imprint" | translate }}</a
        >
        <a
          gaEvent="burger_nav_privacy_policy"
          draggable="false"
          target="_blank"
          [href]="nav.getPrivacyPolicyLink()"
          class="pointer"
          >{{ "FOOTER.Privacy" | translate }}</a
        >
        <a
          gaEvent="burger_nav_cookie_policy"
          [routerLink]="['/', currentLang, 'cookie-policy']"
          (click)="showBurgerMenu = false"
          draggable="false"
          class="pointer"
          >{{ "FOOTER.Cookies" | translate }}</a
        >
        <a
          gaEvent="burger_nav_terms"
          target="_blank"
          draggable="false"
          [href]="nav.getTermsAndConditionsLink()"
          class="pointer"
          >{{ "FOOTER.Terms" | translate }}</a
        >
      </div>
      <div>{{ "FOOTER.Copyright" | translate }}</div>
    </div>
  </div>
</ng-template>
