import { Component, Input, OnInit, inject } from "@angular/core";

import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { LanguageService } from "src/app/core/services/language.service";
import { Collection } from "src/app/models/collection";
import { HomePageModule } from "src/app/models/home-page-module.model";
import { PackageType } from "src/app/models/shared-models/package-type.enum";
import { ProductCompact } from "src/app/models/shared-models/product.model";
import { CollectionService } from "src/app/services/collection.service";
import { OpenAccessCoversComponent } from "src/app/components/open-access-covers/open-access-covers.component";
import { SliderComponent } from "src/app/components/slider/slider.component";

enum LoadStates {
  LOADING,
  LOADED,
  ERROR,
}

@Component({
  selector: "app-open-access-publications",
  templateUrl: "./open-access-publications.component.html",
  styleUrls: ["./open-access-publications.component.scss"],
  standalone: true,
  imports: [
    RouterModule,
    TranslateModule,
    OpenAccessCoversComponent,
    SliderComponent,
  ],
})
export class FreePublicationsComponent implements OnInit {
  private collectionService = inject(CollectionService);
  lang = inject(LanguageService);

  state: LoadStates = LoadStates.LOADING;

  products: ProductCompact[] = [];
  collection?: Collection;

  PackageType = PackageType;
  @Input() module!: HomePageModule;

  breakpoints: any = {
    "(min-width: 576px)": {
      slides: { perView: 2 },
    },
    "(min-width: 992px)": {
      slides: { perView: 3 },
    },
  };

  constructor() {}

  ngOnInit(): void {
    // the data field contains the collection name
    const collectionName = this.module.data;
    this.loadCollection(collectionName);
  }

  async loadCollection(collectionName: string) {
    this.state = LoadStates.LOADING;

    try {
      this.collection = await this.collectionService.loadCollection(
        collectionName
      );
      this.products = this.collection?.products;
      this.state = LoadStates.LOADED;
    } catch (error) {
      this.state = LoadStates.ERROR;
      this.products = [];
      return;
    }
  }
}
