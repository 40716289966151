<section id="catalog">
  <div class="container max-width">
    <img
      class="cover"
      src="{{ 'CATALOG.CoverUrl' | translate }}"
      alt="Jovis Catalog Cover"
    />
    <div class="info">
      <h1 class="title">{{ catalogService.catalogName() }}</h1>
      <div class="buttons" gaCategory="hp_catalog">
        <button
          gaEvent="hp_catalog_look_inside"
          class="large"
          routerLink="/publisher/catalog"
        >
          {{ "CATALOG.LookInsideButton" | translate }}
        </button>
        <button
          gaEvent="hp_catalog_download"
          class="large"
          (click)="downloadCatalog()"
        >
          {{ "CATALOG.DownloadButton" | translate }}
        </button>
      </div>
    </div>
  </div>
</section>
