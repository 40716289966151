import { AfterViewInit, Component, OnInit, inject, input } from "@angular/core";
import { RouterLink } from "@angular/router";
import { LanguageService } from "src/app/core/services/language.service";
import { Book } from "src/app/models/shared-models/book.model";
import { NavbarColor } from "src/app/models/shared-models/navbar-color";
import { ProductSupply } from "src/app/models/shared-models/product-supply.model";
import { ProductService } from "src/app/services/product.service";
import { ShoppingCartService } from "src/app/services/shopping-cart.service";
import { ContributorsGroup } from "./contributors-group.model";
import { SliderItem } from "src/app/models/slider-item.model";
import { SliderItemType } from "src/app/components/slider/slider.component";
import { YumpuDoc } from "src/app/components/yumpu-viewer/yumpu-doc.model";
import { AppImage } from "src/app/models/shared-models/image.model";
import { ScreenSizeService } from "src/app/shared/screen-size.service";
import { IndexabilityService } from "src/app/services/indexability.service";
import { StructuredDataService } from "src/app/services/structured-data.service";
import { SEO } from "src/app/models/shared-models/seo.model";
import { extractAspectRatioFromMeasurement } from "src/app/core/helpers/extractAspectRatioFromMeasurement";
import { TranslateModule } from "@ngx-translate/core";
import { YumpuViewerComponent } from "../../components/yumpu-viewer/yumpu-viewer.component";
import { SimilarBooksComponent } from "./similar-books/similar-books.component";
import { AccordionContent } from "../../shared/accordion/directives/accordion-content.directive";
import { AccordionItem } from "../../shared/accordion/directives/accordion-item.directive";
import { AccordionComponent } from "../../shared/accordion/accordion.component";
import { ShowAboveBelowDirective } from "../../shared/show-above-below.directive";
import { ProductSupplyInfoComponent } from "./product-supply-info/product-supply-info.component";
import { BuyButtonComponent } from "./buy-button/buy-button.component";
import { SliderComponent } from "../../components/slider/slider.component";
import { BackComponent } from "../../components/back/back.component";
import { InlineSVGModule } from "ng-inline-svg-w";
import { MatProgressSpinner } from "@angular/material/progress-spinner";
import { NavbarColorDirective } from "../../shared/navbar-color.directive";
import { NgTemplateOutlet } from "@angular/common";
import { signal } from "@angular/core";

enum PageState {
  LOADING,
  LOADED,
  ERROR,
  NOT_FOUND,
}

interface RelatedTitle {
  isbn: string;
  language: string[];
}

export const navbarColor: NavbarColor = {
  background: "#BAC6CB",
  text: "#1c1c1c",
};

export const detailsNavBarColor: NavbarColor = {
  background: "#D0D8DB",
  text: "#1c1c1c",
};

@Component({
  selector: "app-book",
  templateUrl: "./book.component.html",
  styleUrls: ["./book.component.scss"],
  standalone: true,
  imports: [
    NavbarColorDirective,
    MatProgressSpinner,
    RouterLink,
    InlineSVGModule,
    BackComponent,
    SliderComponent,
    BuyButtonComponent,
    ProductSupplyInfoComponent,
    ShowAboveBelowDirective,
    NgTemplateOutlet,
    AccordionComponent,
    AccordionItem,
    AccordionContent,
    SimilarBooksComponent,
    YumpuViewerComponent,
    TranslateModule,
  ],
})
export class BookComponent implements OnInit, AfterViewInit {
  productService = inject(ProductService);
  shoppingCartService = inject(ShoppingCartService);
  private languageService = inject(LanguageService);
  private screenSizeService = inject(ScreenSizeService);
  private indexabilityService = inject(IndexabilityService);
  private structuredDataService = inject(StructuredDataService);

  state = signal<PageState>(PageState.LOADED);
  PageState = PageState;
  book = input<Book | undefined>(undefined);
  yumpuDoc = signal<YumpuDoc | undefined>(undefined);
  slides = signal<SliderItemType[]>([]);
  sortedProductSupply = signal<ProductSupply[]>([]);
  relatedTitles = signal<RelatedTitle[]>([]);

  navbarColor: NavbarColor = {
    background: "#BAC6CB",
    text: "#1c1c1c",
  };

  detailsNavBarColor: NavbarColor = {
    background: "#D0D8DB",
    text: "#1c1c1c",
  };

  async ngOnInit() {
    const book = this.book();
    if (!book) return;

    const seo: SEO = this.getSeoData(book);
    this.indexabilityService.setMetaTags(seo);
    this.structuredDataService.addStructuredData(book);

    this.setSortedProductSupply(book);
    await this.setRelatedISBNs();

    const slides: SliderItemType[] = [];
    if (book.covers[0]) {
      slides.push(book.covers[0]);
    } else {
      // placeholder cover
      const placeholderCoverSlide: AppImage = {
        urls: [],
        blurHash: "",
        aspectRatio:
          extractAspectRatioFromMeasurement(
            book.productSupply?.[0]?.measurement,
          ) ?? 3 / 4,
      };
      slides.push(placeholderCoverSlide);
    }
    this.slides.set(slides);
  }

  async ngAfterViewInit() {
    const yumpuDoc = await this.productService.getYumpuDoc(
      this.book()?.productSupply?.[0]?.isbn || "",
    );
    this.yumpuDoc.set(yumpuDoc);
  }

  get currentLang(): string {
    return this.languageService.currentLanguage();
  }

  getContributorGroups(): ContributorsGroup[] {
    const book = this.book();
    if (!book) return [];
    return this.productService.getContributorGroups(book);
  }

  getSeoData(book: Book): SEO {
    const currentLang = this.languageService.currentLanguage();

    return {
      title: book.title,
      description: book?.bookData?.description?.[currentLang] ?? "",
      relativeUrl: `book/${book?.productSupply?.[0]?.isbn}`,
    };
  }

  setSortedProductSupply(book: Book) {
    // print first, ebook second
    if (!book.productSupply) return;

    // 'Electronic', 'Hardback', 'Paperback'
    let sorted = book.productSupply.sort((a, b) => {
      if (a.format === "Electronic") return 1;
      if (b.format === "Electronic") return -1;
      return 0;
    });

    // sort the unpublished products to the end, preserve the order
    sorted = sorted.sort((a, b) => {
      const aUnpublished =
        this.productService.getPublishingDateWeeksDifference(a.publishingDate) >
        0;
      const bUnpublished =
        this.productService.getPublishingDateWeeksDifference(b.publishingDate) >
        0;
      if (aUnpublished) return 1;
      if (bUnpublished) return -1;
      return 0;
    });

    this.sortedProductSupply.set(sorted);
  }

  getDescription(): string {
    return this.languageService.getLocalizedInfo(
      this.book()?.bookData?.description,
    );
  }

  getKeywords(): string[] {
    return this.languageService.getLocalizedInfoArray(this.book()?.keywords);
  }

  getAuthorInfo(): string {
    return this.languageService.getLocalizedInfo(
      this.book()?.bookData?.authorInfo,
    );
  }

  getPressLink(): string {
    let url = "";
    const lang = this.languageService.currentLanguage();
    url = `/${lang}/publisher/press`;
    const params = new URLSearchParams();
    params.set("isbn", this.book()?.productSupply?.[0]?.isbn || "");
    params.set("title", this.book()?.title || "");

    url += "?" + params.toString();
    return url;
  }

  isAuthorOrEditor(contributorRole: string): boolean {
    return contributorRole === "A01" || contributorRole === "B01";
  }

  showAccordion(): boolean {
    const hasDescription = !!this.book()?.bookData?.description;
    const hasAuthorInfo = !!this.book()?.bookData?.authorInfo;
    const hasReviews = !!this.book()?.bookData?.reviews?.length;
    if (this.screenSizeService.isMobile()) {
      return hasDescription || hasAuthorInfo || hasReviews;
    } else {
      return hasAuthorInfo || hasReviews;
    }
  }

  async setRelatedISBNs() {
    // TODO: improve this by adding the related titles on the second stage of the parser

    const relatedISBNs = this.book()?.bookData?.relatedISBNs;
    if (!relatedISBNs) return;

    const relatedTitles: RelatedTitle[] = [];
    for (const isbn of relatedISBNs) {
      try {
        const book = await this.productService.getBook(isbn);
        if (book) {
          relatedTitles.push({
            isbn: isbn,
            language: book.language,
          });
        }
      } catch (error) {
        console.error(error);
      }
    }

    this.relatedTitles.set(relatedTitles);
  }

  getRelatedISBNLink(relatedTitle: RelatedTitle): string {
    const lang = this.languageService.currentLanguage();
    return `${lang}/book/` + relatedTitle.isbn;
  }

  trackByProductSupply(index: number, item: ProductSupply): string {
    return item.isbn;
  }

  trackBySeries(index: number, item: any): string {
    return item.seriesKey;
  }

  trackByContributorGroup(index: number, item: any): string {
    return `${item.contributorRole}-${item.names.join("-")}`;
  }

  trackByRelatedTitle(index: number, item: any): string {
    return item.isbn;
  }

  trackByKeyword(index: number, item: string): string {
    return item;
  }
}
