import { Component, Input, Output, EventEmitter } from "@angular/core";

import { TranslateModule } from "@ngx-translate/core";
import { InlineSVGModule } from "ng-inline-svg-w";

@Component({
  selector: "app-popup",
  templateUrl: "./popup.component.html",
  styleUrls: ["./popup.component.scss"],
  standalone: true,
  imports: [TranslateModule, InlineSVGModule],
})
export class PopupComponent {
  @Input() show: boolean = false;
  @Output() showChange = new EventEmitter<boolean>();

  @Input() titleKey: string = "";
  @Input() icon?: string = undefined;

  onClose() {
    this.show = false;
    this.showChange.emit(this.show);
  }
}
