import {
  Component,
  Input,
  OnInit,
  AfterContentInit,
  HostListener,
  inject,
} from "@angular/core";
import { WordPressService } from "src/app/services/word-press.service";
import { LanguageService } from "src/app/core/services/language.service";
import { ScreenSizeService } from "src/app/shared/screen-size.service";
import { HomePageModule } from "src/app/models/home-page-module.model";
import { SliderItem } from "src/app/models/slider-item.model";

import { TranslateModule } from "@ngx-translate/core";
import { SliderComponent } from "src/app/components/slider/slider.component";
import { FadeOnScrollDirective } from "src/app/shared/fade-on-scroll.directive";

enum LoadStates {
  LOADING,
  LOADED,
  ERROR,
}

@Component({
  selector: "app-homepage-slider",
  templateUrl: "./homepage-slider.component.html",
  styleUrls: ["./homepage-slider.component.scss"],
  standalone: true,
  imports: [TranslateModule, SliderComponent, FadeOnScrollDirective],
})
export class HomepageSliderComponent implements OnInit, AfterContentInit {
  private wpService = inject(WordPressService);
  private languageService = inject(LanguageService);
  private screenSizeService = inject(ScreenSizeService);

  state: LoadStates = LoadStates.LOADING;
  allSlides: SliderItem[] = [];
  slides: SliderItem[] = [];

  @Input() module!: HomePageModule;

  constructor() {}

  ngOnInit(): void {}

  hasTransparentNavbar(): boolean {
    const b = this.module.navbarColor?.background;
    return !b || b === "transparent" || b.trim() === "";
  }

  ngAfterContentInit(): void {
    this.loadSlides();
  }

  @HostListener("window:resize", ["$event"])
  filterSlides() {
    // filter based on language and mobile/desktop
    const language = this.languageService.currentLanguage();
    const isMobile = this.screenSizeService.isMobile();

    // note: a slide can be shown on both mobile and desktop
    this.slides = this.allSlides.filter((slide) => {
      if (slide.language !== language) return false;
      if (isMobile && !slide.mobile) return false;
      if (!isMobile && !slide.desktop) return false;
      return true;
    });
  }

  async loadSlides() {
    const sliderName = this.module.data;
    this.state = LoadStates.LOADING;

    try {
      const sliderItems = await this.wpService.getSlider(sliderName);
      this.allSlides = sliderItems;
      this.filterSlides();
      this.state = LoadStates.LOADED;
    } catch (error) {
      this.state = LoadStates.ERROR;
      this.allSlides = [];
      return;
    }
  }
}
