<section id="error" class="full-height" [navbarColor]="navbarColor">
  <div class="not-found max-width">
    <div class="large">{{ "BOOK.notFoundTitle" | translate }}</div>
    <div class="large" [innerHTML]="'BOOK.notFoundMessage' | translate"></div>
    <a routerLink="/books" class="mono"
      >{{ "CART.continueBrowsing" | translate }}
      <span inlineSVG="img/icon-arrow-right.svg"></span>
    </a>
  </div>
</section>
