import { Component, Input, OnInit, signal, inject } from "@angular/core";
import { LanguageService } from "src/app/core/services/language.service";
import { HomePageModule } from "src/app/models/home-page-module.model";
import { ProductCompact } from "src/app/models/shared-models/product.model";
import { CollectionService } from "src/app/services/collection.service";
import { PackageType } from "src/app/models/shared-models/package-type.enum";

import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { NgxGoogleAnalyticsModule } from "ngx-google-analytics";
import { SeriesCompactComponent } from "src/app/components/shop/series-compact/series-compact.component";
import { ProductCompactComponent } from "src/app/components/shop/product-compact/product-compact.component";
import { SliderComponent } from "src/app/components/slider/slider.component";
import { ShowAboveBelowDirective } from "src/app/shared/show-above-below.directive";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";

enum LoadStates {
  LOADING,
  LOADED,
  ERROR,
}

@Component({
  selector: "app-homepage-shop",
  templateUrl: "./homepage-shop.component.html",
  styleUrls: ["./homepage-shop.component.scss"],
  standalone: true,
  imports: [
    RouterModule,
    TranslateModule,
    NgxGoogleAnalyticsModule,
    SeriesCompactComponent,
    ProductCompactComponent,
    SliderComponent,
    ShowAboveBelowDirective,
    MatProgressSpinnerModule,
  ],
})
export class HomepageShopComponent implements OnInit {
  private collectionService = inject(CollectionService);
  lang = inject(LanguageService);

  LoadStates = LoadStates;
  state = signal<LoadStates>(LoadStates.LOADING);
  products = signal<ProductCompact[]>([]);
  PackageType = PackageType;

  @Input() module!: HomePageModule;

  breakpoints = {
    "(min-width: 576px)": {
      slides: { perView: 2 },
    },
    "(min-width: 992px)": {
      slides: { perView: 3 },
    },
  };

  constructor() {}

  ngOnInit(): void {
    // the data field contains the collection name
    const collectionName = this.module.data;
    this.loadCollection(collectionName);
  }

  async loadCollection(collectionName: string) {
    this.state.set(LoadStates.LOADING);

    try {
      const collection =
        await this.collectionService.loadCollection(collectionName);
      this.products.set(collection.products);
      this.state.set(LoadStates.LOADED);
    } catch (error) {
      this.state.set(LoadStates.ERROR);
      this.products.set([]);
      return;
    }
  }
}
