import { inject, PLATFORM_ID } from "@angular/core";
import {
  CanActivateFn,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  UrlTree,
} from "@angular/router";
import { LanguageService } from "../services/language.service";

export const redirectGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
): boolean | UrlTree => {
  const platformId = inject(PLATFORM_ID);

  const languageService = inject(LanguageService);
  const router = inject(Router);
  const currentLang = languageService.currentLanguage();

  if (!currentLang) {
    languageService.initLanguage();
    // throw new Error("Current language not found");
  }

  // Get the full path without the base URL
  const fullPath = state.url;

  // If already has language prefix, don't redirect
  if (fullPath.startsWith("/en/") || fullPath.startsWith("/de/")) {
    return true;
  }

  // If it's just the language code without slash, don't redirect
  if (fullPath === "/en" || fullPath === "/de") {
    return true;
  }

  // Remove leading slash for proper joining
  const cleanPath = fullPath.startsWith("/") ? fullPath.substring(1) : fullPath;

  // Create new path with language prefix
  const newPath = `/${currentLang}/${cleanPath}`;

  const navigationExtras = {
    queryParams: router.getCurrentNavigation()?.extras.queryParams,
  };

  return router.createUrlTree(
    newPath.split("/").filter(Boolean),
    navigationExtras,
  );
};
