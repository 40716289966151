import { inject } from "@angular/core";
import { ResolveFn } from "@angular/router";
import { WPItem } from "@app/models/wp-item.model";
import { WordPressService } from "@app/services/word-press.service";
import { homepageThematicWpSlug } from "./homepage-thematic-categories/homepage-thematic-categories.component";

export const homepageThematicCategoriesResolver: ResolveFn<
  WPItem
> = async () => {
  const wp = inject(WordPressService);
  return wp.getPage(homepageThematicWpSlug);
};
