@if (slides.length) {
  <section id="slider">
    <div class="container">
      <slider #sliderComp [slides]="slides" [slidesPerView]="1"></slider>
    </div>
    <!-- Slider Credit Line -->
    @if (sliderComp) {
      @if (slides[sliderComp.currentSlide]; as slide) {
        <div
          class="slider-credit-container"
          [fadeOnScroll]="hasTransparentNavbar()"
        >
          <span class="mono small" [style.color]="slide.textColor">
            <span>{{ slide.creditLineText }}&#8203;</span>
            <span class="credit-line-details">
              <span>&nbsp;{{ "SLIDER.from" | translate }}&nbsp;</span>
              <a [href]="slide.creditLink"
                ><strong>{{ slide.creditLineLinkText }}</strong
                >&nbsp;-&gt;</a
              >
            </span>
          </span>
        </div>
      }
    }
  </section>
}
