import { AuthService, UserObject } from "@app/core/services/auth.service";
import { inject } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  RedirectCommand,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { environment } from "@environments/environment";
import { LanguageService } from "../services/language.service";

export const languageGuard: CanActivateFn = async (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  const authService = inject(AuthService);
  const router = inject(Router);
  const languageService = inject(LanguageService);

  const existingUser = await authService.initUser();

  await languageService.initLanguage();

  if (!existingUser) {
    return true;
  }
  const user = authService.user() as UserObject;

  const langs = environment.supportedLanguages;
  const firstRoutePath = route.url[0]?.path;
  const currentRouteLanguage = langs.includes(firstRoutePath)
    ? firstRoutePath
    : "";
  const currentUserLanguage = langs.includes(user?.language)
    ? user?.language
    : environment.defaultLanguage;

  const differentLanguage = currentRouteLanguage !== currentUserLanguage;

  if (!differentLanguage) {
    return true;
  } else {
    // replace the current route with the language of the user
    // eg: / -> /de
    if (!currentRouteLanguage) {
      // redirect to the home page
      return new RedirectCommand(router.createUrlTree([user.language]));
    } else {
      // redirect to the current route with the language of the user
      // eg: /en/search -> /de/search
      // eg: /en/search?query=test -> /de/search?query=test

      // replace the first part of the url with the language of the user
      const restOfUrl = state.url.split("/").slice(2).join("/");
      const urlTree = router.createUrlTree(
        [user.language, ...restOfUrl.split("/")],
        {
          queryParams: route.queryParams,
        },
      );

      return new RedirectCommand(urlTree, { replaceUrl: true });
    }
  }
};
