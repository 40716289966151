import { Injectable, Renderer2, RendererFactory2, inject } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { formatDescription, stripHtmlTags } from "../core/helpers/helpers";
import { ProductSupply } from "../models/shared-models/product-supply.model";
import { LicenseType } from "../models/shared-models/license-type.enum";
import { ActivatedRoute } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class StructuredDataService {
  private rendererFactory = inject(RendererFactory2);
  private document = inject<Document>(DOCUMENT);

  private renderer: Renderer2;

  private route = inject(ActivatedRoute);

  constructor() {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  addStructuredData(book: any): void {
    const script = this.renderer.createElement("script");
    this.renderer.setAttribute(script, "type", "application/ld+json");

    const ps = book.productSupply?.[0] as ProductSupply;

    if (!ps) return;

    const structuredData = {
      "@context": "https://schema.org",
      "@type": ["Product", "Book"],
      name: book.title,
      image: book.covers?.[0]?.urls[0],
      isbn: ps.isbn,
      gtin: ps.isbn,
      //TODO: add en/de description - fallback to the other if the current one is missing
      description: formatDescription(book.bookData?.description?.en, 160),
      datePublished: ps.publishingDate
        ? new Date(ps.publishingDate).toISOString().split("T")[0]
        : "",
      audience: null,
      headline: book.title,
      //TODO: add en/de keywords - fallback to the other if the current one is missing
      keywords: book.keywords?.en?.join(", "),
      isAccessibleForFree: ps.license == LicenseType.OpenAccess,
      author: book.bookData?.contributors?.map((contributor: any) => ({
        "@type": "Person",
        name: contributor.name,
      })),
      publisher: {
        "@type": "Organization",
        name: "JOVIS Verlag",
      },
      brand: {
        "@type": "Brand",
        name: "JOVIS Verlag",
      },
      // TODO: include all product supply variants
      offers: {
        "@type": "Offer",
        availability: "https://schema.org/InStock",
        price: ps.prices?.[0]?.amount,
        priceCurrency: ps.prices?.[0]?.currency,
        url: this.route.snapshot.url.map((segment) => segment.path).join("/"),
      },
      itemCondition: "https://schema.org/NewCondition",
      url: this.route.snapshot.url.map((segment) => segment.path).join("/"), // current page URL
    };

    const json = JSON.stringify(structuredData, null, 2);
    const textNode = this.renderer.createText(json);
    this.renderer.appendChild(script, textNode);

    const head = this.document.head;
    this.renderer.appendChild(head, script);
  }
}
