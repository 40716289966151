@if (state() === LoadStates.LOADING || products().length) {
  <section id="shop">
    <div class="container max-width" gaCategory="hp_shop">
      <h1 class="title">{{ "SHOP.NewReleases" | translate }}</h1>
      @if (state() === LoadStates.LOADING) {
        <div class="loading-container">
          <mat-spinner strokeWidth="2" diameter="85"></mat-spinner>
        </div>
      }
      <div id="products" class="max-width" *appShowAbove="'sm'">
        @for (product of products(); track product) {
          @if (product.packageType == PackageType.series) {
            <app-series-compact
              gaEventPrefix="hp_shop_"
              [product]="product"
            ></app-series-compact>
          }
          @if (product.packageType == PackageType.book) {
            <app-product-compact
              gaEventPrefix="hp_shop_"
              [product]="product"
            ></app-product-compact>
          }
        }
      </div>
      @defer {
        @if (products().length > 0) {
          <div id="products-sm" *appShowBelow="'sm'">
            <slider
              [slides]="products()"
              [slidesPerView]="1"
              [breakpoints]="breakpoints"
              [showDots]="false"
              [loop]="false"
              [origin]="'auto'"
              [fullHeight]="false"
            >
            </slider>
          </div>
        }
      }
      <button
        class="large"
        gaCategory="hp_shop"
        gaEvent="hp_shop_all_new_releases"
        [routerLink]="['/', lang.currentLanguage(), 'search']"
        [queryParams]="{ tags: 'new', sortBy: 'date', sortOrder: 'DESC' }"
      >
        {{ "SHOP.AllNewReleases" | translate }}
      </button>
    </div>
  </section>
}
