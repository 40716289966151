import { Component, Input } from "@angular/core";
import { ProductCompact } from "src/app/models/shared-models/product.model";
import { CommonModule } from "@angular/common";
import { ProductCompactComponent } from "../shop/product-compact/product-compact.component";

@Component({
  selector: "app-open-access-covers",
  templateUrl: "./open-access-covers.component.html",
  styleUrls: ["./open-access-covers.component.scss"],
  standalone: true,
  imports: [CommonModule, ProductCompactComponent],
})
export class OpenAccessCoversComponent {
  @Input() products!: ProductCompact[];
}
