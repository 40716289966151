import { inject } from "@angular/core";
import { ResolveFn } from "@angular/router";
import { AuthService } from "@app/core/services/auth.service";
import { LanguageService } from "@app/core/services/language.service";
import { WPItem } from "@app/models/wp-item.model";
import { NewsService } from "src/app/services/news.service";

export const newsResolver: ResolveFn<WPItem[]> = async () => {
  const newsService = inject(NewsService);
  console.warn(inject(LanguageService).currentLanguage());
  await newsService.reloadAll();
  return newsService.news();
};
