@if (state() == PageState.LOADING) {
  <section id="loader" class="full-height" [navbarColor]="navbarColor">
    <!-- add mat-spinner -->
    <mat-spinner strokeWidth="2" [diameter]="85"> </mat-spinner>
  </section>
}
@if (book() && state() == PageState.LOADED) {
  <section id="book-cover" [navbarColor]="navbarColor">
    <section id="back-button">
      <div class="back-button-container max-width">
        <app-back></app-back>
      </div>
    </section>
    @if (slides().length > 0) {
      <slider
        [slides]="slides()"
        [slidesPerView]="1"
        [fullHeight]="false"
        [showDots]="false"
      ></slider>
    }
    <div class="buttons action a max-width">
      <div class="buttons cite-share"></div>
      <div class="buttons buy">
        @for (p of sortedProductSupply(); track trackByProductSupply(0, p)) {
          <app-buy-button [productSupply]="p" [book]="book()!"></app-buy-button>
        }
      </div>
    </div>
  </section>
  @defer {
    <section id="details" [navbarColor]="detailsNavBarColor">
      <div class="container max-width">
        <div class="columns">
          <div class="column one">
            <div>
              <h1 class="book-title" [innerHTML]="book()!.title"></h1>
              @if (book()!.bookData?.subtitle) {
                <h2
                  class="subtitle"
                  [innerHTML]="book()!.bookData?.subtitle"
                ></h2>
              }
            </div>
            @if (getContributorGroups()) {
              <div class="contributors">
                @for (
                  contributorGroup of getContributorGroups();
                  track trackByContributorGroup(0, contributorGroup)
                ) {
                  <div>
                    @if (!isAuthorOrEditor(contributorGroup.contributorRole)) {
                      <span
                        >{{
                          contributorGroup.translationKey | translate
                        }}&nbsp;</span
                      >
                    }
                    <span
                      [innerHTML]="contributorGroup.names.join(' / ')"
                    ></span>
                    @if (isAuthorOrEditor(contributorGroup.contributorRole)) {
                      <span
                        >&nbsp;{{
                          contributorGroup.translationKey | translate
                        }}</span
                      >
                    }
                  </div>
                }
              </div>
            }
            @for (
              series of book()!.bookData?.seriesInfo || [];
              track trackBySeries(0, series)
            ) {
              <div>
                <div>
                  <a [href]="productService.getSeriesLink(series.seriesKey)">
                    <span
                      [innerHTML]="
                        'BOOK.InSeries' | translate: { series: series }
                      "
                    ></span>
                    &nbsp;<span inlineSVG="img/icon-arrow-right.svg"></span>
                  </a>
                </div>
              </div>
            }
            <div class="description sm-up" [innerHTML]="getDescription()"></div>
          </div>
          <div class="column two">
            @for (
              productSupplyItem of sortedProductSupply();
              track trackByProductSupply(0, productSupplyItem)
            ) {
              <app-product-supply-info
                [productSupply]="productSupplyItem"
                [book]="book()!"
                [isFirst]="$index === 0"
              ></app-product-supply-info>
            }
            <div>
              <a [href]="getPressLink()">
                {{ "GLOBALS.Press" | translate }}
                &nbsp;<span inlineSVG="img/icon-arrow-right.svg"></span>
              </a>
            </div>
            <div>
              @for (b of relatedTitles(); track trackByRelatedTitle(0, b)) {
                <a [href]="getRelatedISBNLink(b)">
                  {{ "BOOK.seeThisBookIn-" + b.language[0] | translate }}
                  &nbsp;<span inlineSVG="img/icon-arrow-right.svg"></span>
                </a>
              }
            </div>
            <ng-container *appShowAbove="'sm'">
              <ng-container
                *ngTemplateOutlet="
                  keywords;
                  context: { $implicit: getKeywords() }
                "
              >
              </ng-container>
            </ng-container>
            <ng-container>
              <div>
                <span
                  [innerHTML]="'BOOK.product-safety-headline' | translate"
                ></span>
                <br>
                <span
                  [innerHTML]="'BOOK.product-safety-info' | translate"
                ></span>
              </div>
            </ng-container>
          </div>
        </div>
        @if (showAccordion()) {
          <div class="accordion">
            <div class="columns">
              <accordion [collapsing]="false">
                <accordion-item
                  *appShowBelow="'sm'"
                  [title]="'BOOK.description' | translate"
                >
                  <ng-template [accordionContent]
                    ><div
                      class="description margin-bottom"
                      [innerHTML]="getDescription()"
                    ></div>
                  </ng-template>
                </accordion-item>
                @if (book()!.bookData?.authorInfo) {
                  <accordion-item [title]="'BOOK.author' | translate"
                    ><ng-template [accordionContent]
                      ><div
                        class="description margin-bottom"
                        [innerHTML]="getAuthorInfo()"
                      ></div>
                    </ng-template>
                  </accordion-item>
                }
                @if (book()!.bookData?.reviews?.length) {
                  <accordion-item [title]="'BOOK.reviews' | translate"
                    ><ng-template [accordionContent]>
                      <div
                        class="description margin-bottom"
                        [innerHtml]="book()!.bookData?.reviews"
                      ></div>
                    </ng-template>
                  </accordion-item>
                }
              </accordion>
            </div>
          </div>
        }
        @defer (on idle) {
          <ng-container *appShowBelow="'sm'">
            <ng-container
              *ngTemplateOutlet="
                keywords;
                context: { $implicit: getKeywords() }
              "
            >
            </ng-container>
          </ng-container>
        }
      </div>
    </section>
    <ng-container
      [ngTemplateOutletContext]="{ $implicit: yumpuDoc() }"
      [ngTemplateOutlet]="lookInside"
    >
    </ng-container>
    <app-similar-books [book]="book()!"></app-similar-books>
  }
}

<ng-template #keywords let-keywords>
  <div class="keywords">
    {{ "BOOK.keywords" | translate }}:<br />
    @for (k of keywords; track trackByKeyword(0, k)) {
      <a [routerLink]="['/', currentLang, 'search']" [queryParams]="{ q: k }">{{
        k
      }}</a>
      @if ($index < keywords.length - 1) {
        <span>, </span>
      }
    }
  </div>
</ng-template>

<ng-template #lookInside let-yumpuDoc>
  @if (yumpuDoc) {
    <section id="look-inside">
      <h1 class="title">{{ "BOOK.lookInside" | translate }}</h1>
      <app-yumpu-viewer
        class="max-width"
        [magazineId]="yumpuDoc.yumpuID"
      ></app-yumpu-viewer>
    </section>
  }
</ng-template>
