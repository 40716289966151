<div id="covers">
  @for (product of products; track product; let i = $index) {
    <div
      class="cover-container"
      [ngClass]="{
        first: i === 0,
        second: i === 1,
        third: i === 2
      }"
      >
      <app-product-compact
        [product]="product"
        [gaEventPrefix]="'hp_open_access_'"
        [coverOnly]="true"
      ></app-product-compact>
    </div>
  }
</div>
