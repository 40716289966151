@if (state() !== HomepageStates.LOADED) {
  <section id="loader" class="full-height">
    <mat-spinner strokeWidth="2" [diameter]="85"> </mat-spinner>
  </section>
}
<!-- first module -->
@if (topModule) {
  <div
    class="module-wrapper"
    id="{{ topModule.id }}"
    [navbarColor]="topModule.navbarColor"
  >
    <ng-container
      *ngTemplateOutlet="
        moduleSwitch;
        context: { $implicit: topModule, index: 0 }
      "
    ></ng-container>
  </div>
}
<!-- other modules - defer until idle -->
@for (module of otherModules; track module.id; let index = $index) {
  @defer (on idle) {
    <div
      class="module-wrapper"
      id="{{ module.id }}"
      [navbarColor]="module.navbarColor"
    >
      <ng-container
        *ngTemplateOutlet="
          moduleSwitch;
          context: { $implicit: module, index: index }
        "
      ></ng-container>
    </div>
  } @placeholder {
    <section
      id="loader"
      class="full-height"
      [style.background]="module.navbarColor?.background"
    >
      <mat-spinner strokeWidth="2" [diameter]="85"> </mat-spinner>
    </section>
  }
}

<ng-template #moduleSwitch let-module let-index="index">
  @if (module.id === "news") {
    <app-news id="app-news" [compact]="true"></app-news>
  }
  @if (module.id === "newsletter") {
    <app-newsletter id="app-newsletter"></app-newsletter>
  }
  @if (module.id === "open-access-publications") {
    <app-open-access-publications
      id="app-open-access-publications"
      [module]="module"
    ></app-open-access-publications>
  }
  @if (module.id === "homepage-slider") {
    <app-homepage-slider
      id="app-homepage-slider"
      [module]="module"
    ></app-homepage-slider>
  }
  @if (module.id === "homepage-shop") {
    <app-homepage-shop
      id="app-homepage-shop"
      [module]="module"
    ></app-homepage-shop>
  }
  @if (module.id === "homepage-series") {
    <app-homepage-series
      id="app-homepage-series"
      [module]="module"
    ></app-homepage-series>
  }
  @if (module.id === "catalog") {
    <app-catalog id="app-catalog"></app-catalog>
  }
  @if (module.id === "homepage-thematic-categories") {
    <app-homepage-thematic-categories
      id="app-homepage-thematic-categories"
      [module]="module"
    ></app-homepage-thematic-categories>
  }
</ng-template>
