import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  QueryList,
  Renderer2,
  ViewChildren,
  inject,
  computed,
  SecurityContext,
} from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

import { Router, RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { NgxGoogleAnalyticsModule } from "ngx-google-analytics";
import { BlurhashDirective } from "src/app/shared/blurhash.directive";
import { FadeOnScrollDirective } from "src/app/shared/fade-on-scroll.directive";
import { HomePageModule } from "src/app/models/home-page-module.model";
import { WPItem } from "src/app/models/wp-item.model";
import { SearchService } from "src/app/services/search.service";
import { WordPressService } from "src/app/services/word-press.service";

export const homepageThematicWpSlug = "homepage-thematic-module";

@Component({
  selector: "app-homepage-thematic-categories",
  templateUrl: "./homepage-thematic-categories.component.html",
  styleUrls: ["./homepage-thematic-categories.component.scss"],
  standalone: true,
  imports: [
    RouterModule,
    TranslateModule,
    NgxGoogleAnalyticsModule,
    BlurhashDirective,
    FadeOnScrollDirective,
  ],
})
export class HomepageThematicCategoriesComponent implements OnInit {
  private wp = inject(WordPressService);
  private router = inject(Router);
  private renderer = inject(Renderer2);
  private sanitizer = inject(DomSanitizer);

  @Input() module!: HomePageModule;

  subjects: string[] = [];
  page?: WPItem;

  safeAlt = computed(() => {
    const unsafeValue = this.page?.title ?? "";
    const sanitized =
      this.sanitizer.sanitize(SecurityContext.HTML, unsafeValue) || "";
    return sanitized;
  });

  constructor() {}

  ngOnInit(): void {
    this.subjects = this.module.data.split("\r\n");
    if (this.wp.pageCache.has(homepageThematicWpSlug)) {
      this.page = this.wp.pageCache.get(homepageThematicWpSlug);
    } else {
      this.loadBackground();
    }
  }

  async loadBackground() {
    this.page = await this.wp.getPage(homepageThematicWpSlug);
    // console.log(this.page);
  }

  get creditLineText(): string {
    return this.page?.acf?.credit_line_text || "";
  }

  get creditLineLinkText(): string {
    return this.page?.acf?.credit_line_link_text || "";
  }

  get creditLink(): string {
    return this.page?.acf?.credit_link || "#";
  }

  get creditLineTextColor(): string {
    return this.page?.acf?.credit_line_text_color || "#1c1c1c";
  }

  setSubjectSelected(subject: string) {
    this.router.navigate(["/search"], { queryParams: { subjects: subject } });
  }
}
