import { Component } from "@angular/core";

import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { NavbarColorDirective } from "src/app/shared/navbar-color.directive";

@Component({
  selector: "app-newsletter",
  templateUrl: "./newsletter.component.html",
  styleUrls: ["./newsletter.component.scss"],
  standalone: true,
  imports: [RouterModule, TranslateModule, NavbarColorDirective],
})
export class NewsletterComponent {}
