import { NgTemplateOutlet } from "@angular/common";
import { Component } from "@angular/core";
import { MatProgressSpinner } from "@angular/material/progress-spinner";
import { RouterLink } from "@angular/router";
import { BackComponent } from "@app/components/back/back.component";
import { AccordionComponent } from "@app/shared/accordion/accordion.component";
import { AccordionContent } from "@app/shared/accordion/directives/accordion-content.directive";
import { AccordionItem } from "@app/shared/accordion/directives/accordion-item.directive";
import { NavbarColorDirective } from "@app/shared/navbar-color.directive";
import { ShowAboveBelowDirective } from "@app/shared/show-above-below.directive";
import { TranslateModule } from "@ngx-translate/core";
import { InlineSVGModule } from "ng-inline-svg-w";
import { navbarColor } from "./book.component";

@Component({
  selector: "app-book-missing",
  templateUrl: "./book.missing.component.html",
  styleUrls: ["./book.component.scss"],
  standalone: true,
  imports: [
    NavbarColorDirective,
    RouterLink,
    InlineSVGModule,
    BackComponent,
    ShowAboveBelowDirective,
    NgTemplateOutlet,
    AccordionComponent,
    AccordionItem,
    AccordionContent,
    TranslateModule,
  ],
})
export class BookMissingComponent {
  navbarColor = navbarColor;
}
