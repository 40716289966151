import { Component, OnInit, signal, inject, Input } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { JovisTitleService } from "src/app/core/services/jovis-title.service";
import { HomePageModule } from "src/app/models/home-page-module.model";
import { HomepageModulesService } from "src/app/services/homepage-modules.service";
import { NavbarService } from "src/app/services/navbar.service";
import { NavbarColorDirective } from "src/app/shared/navbar-color.directive";
import { NewsletterComponent } from "./newsletter/newsletter.component";
import { FreePublicationsComponent } from "./open-access-publications/open-access-publications.component";
import { HomepageSliderComponent } from "./homepage-slider/homepage-slider.component";
import { HomepageShopComponent } from "./homepage-shop/homepage-shop.component";
import { HomepageSeriesComponent } from "./homepage-series/homepage-series.component";
import { CatalogComponent } from "./catalog/catalog.component";
import { HomepageThematicCategoriesComponent } from "./homepage-thematic-categories/homepage-thematic-categories.component";
import { NewsComponent } from "../news/news-page/news.component";
import { ActivatedRoute } from "@angular/router";
import { WPItem } from "@app/models/wp-item.model";

enum HomepageStates {
  LOADING = "loading",
  LOADED = "loaded",
  ERROR = "error",
}

@Component({
  selector: "app-homepage",
  templateUrl: "./homepage.component.html",
  styleUrls: ["./homepage.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    NavbarColorDirective,
    NewsletterComponent,
    FreePublicationsComponent,
    HomepageSliderComponent,
    HomepageShopComponent,
    HomepageSeriesComponent,
    CatalogComponent,
    HomepageThematicCategoriesComponent,
    NewsComponent,
  ],
})
export class HomepageComponent implements OnInit {
  homepageModulesService = inject(HomepageModulesService);
  navbarService = inject(NavbarService);
  private title = inject(JovisTitleService);
  private route = inject(ActivatedRoute);

  public state = signal<HomepageStates>(HomepageStates.LOADING);
  HomepageStates = HomepageStates;

  @Input() homepageThematicCategories!: WPItem;
  @Input() modules!: HomePageModule[];

  topModule?: HomePageModule;
  otherModules?: HomePageModule[];

  constructor() {}

  ngOnInit(): void {
    this.topModule = this.modules[0];
    this.otherModules = this.modules.slice(1);

    this.title.setTitle();

    this.state.set(HomepageStates.LOADED);
  }
}
