import {
  APP_INITIALIZER,
  ApplicationConfig,
  importProvidersFrom,
  PLATFORM_ID,
  inject,
  provideZoneChangeDetection,
} from "@angular/core";
import { routerProvider } from "./app-routing.module";
import { provideClientHydration } from "@angular/platform-browser";
import { provideAnimations } from "@angular/platform-browser/animations";
import { provideHttpClient, withFetch } from "@angular/common/http";
import {
  CommonModule,
  CurrencyPipe,
  DatePipe,
  registerLocaleData,
} from "@angular/common";
import localeDe from "@angular/common/locales/de";
import localeEn from "@angular/common/locales/en";
import {
  NgxGoogleAnalyticsModule,
  NgxGoogleAnalyticsRouterModule,
} from "ngx-google-analytics";
import { SsrCookieService, REQUEST } from "ngx-cookie-service-ssr";
import { environment } from "../environments/environment";
import { InlineSVGModule } from "ng-inline-svg-w";
import { IpCountryService } from "./services/ip-country.service";
import { IndexabilityService } from "./services/indexability.service";
import { YumpuService } from "./components/yumpu-viewer/yumpu.service";
import { CollectionService } from "./services/collection.service";
import { AncillaryService } from "./services/ancillary.service";
import { ProductService } from "./services/product.service";
import { NewsService } from "./services/news.service";
import { AuthService } from "./core/services/auth.service";
import { LanguageService, translate } from "./core/services/language.service";
import { appInitializerFactory } from "./core/services/appInitializerFactory";
import { NavbarService } from "./services/navbar.service";
import { WordPressService } from "./services/word-press.service";

// Register German locale
registerLocaleData(localeDe);
registerLocaleData(localeEn);

const imports = [
  NgxGoogleAnalyticsModule.forRoot(environment.googleTrackingCode),
  NgxGoogleAnalyticsRouterModule,
  CommonModule,
];

const inlineSVG = InlineSVGModule.forRoot({ baseUrl: "/assets/" });

export const appConfig: ApplicationConfig = {
  providers: [
    AuthService,
    LanguageService,
    importProvidersFrom(imports),
    translate.providers!,
    inlineSVG.providers!,
    DatePipe,
    CurrencyPipe,
    NavbarService,
    NewsService,
    ProductService,
    AncillaryService,
    CollectionService,
    YumpuService,
    IndexabilityService,
    IpCountryService,
    WordPressService,
    SsrCookieService,
    // provideZoneChangeDetection({ eventCoalescing: true }),
    routerProvider,
    provideHttpClient(withFetch()),
    provideClientHydration(),
    provideAnimations(),
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: appInitializerFactory,
    //   deps: [LanguageService, AuthService],
    //   multi: true,
    // },
  ],
};
