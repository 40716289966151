import { inject } from "@angular/core";
import { ResolveFn } from "@angular/router";
import { ProductService } from "src/app/services/product.service";
import { Book } from "src/app/models/shared-models/book.model";

export const bookResolver: ResolveFn<Book | null> = async (route) => {
  const productService = inject(ProductService);
  const isbn = route.paramMap.get("isbn");

  if (!isbn) {
    return null;
  }

  try {
    const response = await productService.getBook(isbn);
    const error = response as any;
    if (error["statusCode"] === 404) {
      return null;
    }
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};
