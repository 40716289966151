import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { inject } from "@angular/core";
import { SearchService } from "src/app/services/search.service";
import { from, of } from "rxjs";

export const bookNameHtmlRedirectGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const searchService = inject(SearchService);
  const router = inject(Router);
  const path = route.params["path"];

  if (path.endsWith(".html")) {
    const title = path.split("/").pop().replace(".html", "").replace(/_/g, " ");

    async function getUrl(title: string): Promise<UrlTree> {
      console.log(title);
      const isbn = await searchService.getISBNbyTitle(title);

      if (isbn) {
        return router.createUrlTree([`/book/${isbn}`]);
      } else {
        return router.createUrlTree(["/books"]);
      }
    }

    if (title == "gam-20") {
      return router.createUrlTree(["/book/9783986120665"]);
    }

    return from(getUrl(title));
  } else {
    return of(router.createUrlTree(["/books"]));
  }
};
