@if (!isBannerHidden) {
  <div class="cookie-banner mono small">
    <div class="cookie-container my-3 max-width">
      <div class="pe-md-4 pb-3 pb-md-0">
        <div class="cookie-title">{{ "COOKIE.cookieTitle" | translate }}</div>
        {{ "COOKIE.weUseCookies" | translate }}
        <a [href]="'COOKIE.policyUrl' | translate">{{
          "COOKIE.findOut" | translate
        }}</a>
      </div>
      <div class="cookie-button-container">
        <button class="btn small no-wrap active" (click)="onAccept()">
          {{ "COOKIE.accept" | translate }}
        </button>
        <button class="btn small no-wrap" (click)="onReject()">
          {{ "COOKIE.reject" | translate }}
        </button>
      </div>
    </div>
  </div>
}

@if (!isReducedBannerHidden) {
  <div class="cookie-banner mono small">
    <div class="cookie-container max-width">
      <div class="pe-md-4 pb-3 pb-md-0 w-100">
        <div class="cookie-title">
          {{ "COOKIE.cookieTitleReduced" | translate }}
        </div>
        {{ "COOKIE.weUseCookiesReduced" | translate }}
        <a [href]="'COOKIE.policyUrl' | translate">{{
          "COOKIE.seePolicy" | translate
        }}</a>
      </div>
      <div class="cookie-button-container">
        <button class="btn small no-wrap" (click)="onClose()">
          {{ "COOKIE.close" | translate }}
        </button>
      </div>
    </div>
  </div>
}
