import { inject, Injectable } from "@angular/core";
import { Router, Route, Routes } from "@angular/router";
import { ScreenSizeService } from "@app/shared/screen-size.service";
import { firstValueFrom, isObservable } from "rxjs";

interface RouteData {
  path: string;
  hasParams: boolean;
}

interface LoadedRouteModule {
  routes: Routes;
}

@Injectable({ providedIn: "root" })
export class ExportRoutesService {
  private readonly LOAD_TIMEOUT = 10000; // 10 seconds timeout
  private processedPaths = new Set<string>();
  private screenSizeService = inject(ScreenSizeService);
  private router = inject(Router);

  constructor() {}

  async getAllRoutes(): Promise<string[]> {
    console.log("Starting route extraction...");
    this.processedPaths.clear();
    try {
      const routes = await this.extractRoutes(this.router.config);
      return this.processRoutes(routes);
    } catch (error) {
      console.error("Failed to extract routes:", error);
      return [];
    }
  }

  private processRoutes(routes: RouteData[]): string[] {
    return routes
      .filter((route) => route.path && route.path.length > 0)
      .map((route) => route.path.replace(/\/+/g, "/"))
      .filter((route) => route !== "/")
      .sort((a, b) => {
        const aHasParams = a.includes(":");
        const bHasParams = b.includes(":");
        if (aHasParams !== bHasParams) {
          return aHasParams ? 1 : -1;
        }
        return a.localeCompare(b);
      });
  }

  private async extractRoutes(
    routes: Routes,
    parentPath: string = "",
    processedModules: Set<any> = new Set(),
    depth: number = 0,
  ): Promise<RouteData[]> {
    let routesList: RouteData[] = [];
    const indent = "  ".repeat(depth);

    for (const route of routes) {
      if (route.path !== undefined) {
        const path = route.path === "" ? "/" : route.path;
        const hasParams = path.includes(":");
        const fullPath =
          parentPath === "/" ? `/${path}` : `${parentPath}/${path}`;
        const cleanPath = fullPath.replace(/\/+/g, "/").replace(/\/$/, "");

        if (this.processedPaths.has(cleanPath)) {
          console.warn(`${indent}Circular path detected: ${cleanPath}`);
          continue;
        }
        this.processedPaths.add(cleanPath);

        console.log(`${indent}Processing route: ${cleanPath}`);

        if (cleanPath) {
          routesList.push({ path: cleanPath, hasParams });
          if (!cleanPath.match(/^\/(en|de)($|\/)/)) {
            routesList.push({ path: `/en${cleanPath}`, hasParams });
            routesList.push({ path: `/de${cleanPath}`, hasParams });
          }
        }

        if (route.children) {
          console.log(`${indent}Processing children of: ${cleanPath}`);
          try {
            const childRoutes = await this.extractRoutes(
              route.children,
              cleanPath,
              processedModules,
              depth + 1,
            );
            routesList = routesList.concat(childRoutes);
          } catch (error) {
            console.error(
              `${indent}Error processing children of ${cleanPath}:`,
              error,
            );
          }
        }

        if (route.loadChildren && typeof route.loadChildren === "function") {
          if (!processedModules.has(route.loadChildren)) {
            console.log(`${indent}Loading lazy module for: ${cleanPath}`);
            processedModules.add(route.loadChildren);

            try {
              const loadPromise = Promise.race([
                Promise.resolve(route.loadChildren()),
                new Promise((_, reject) =>
                  setTimeout(
                    () =>
                      reject(
                        new Error(`Timeout loading module for ${cleanPath}`),
                      ),
                    this.LOAD_TIMEOUT,
                  ),
                ),
              ]);

              const result = await loadPromise;
              console.log(
                `${indent}Successfully loaded module for: ${cleanPath}`,
              );

              const loaded = isObservable(result)
                ? await firstValueFrom(result)
                : result;

              let moduleRoutes: Routes = [];

              if (Array.isArray(loaded)) {
                moduleRoutes = loaded;
              } else if (this.isLoadedRouteModule(loaded)) {
                moduleRoutes = loaded.routes;
              }

              if (moduleRoutes.length > 0) {
                console.log(
                  `${indent}Processing module routes for: ${cleanPath}`,
                );
                const lazyRoutes = await this.extractRoutes(
                  moduleRoutes,
                  cleanPath,
                  processedModules,
                  depth + 1,
                );
                routesList = routesList.concat(lazyRoutes);
              } else {
                console.warn(
                  `${indent}No valid routes found in module: ${cleanPath}`,
                );
              }
            } catch (error) {
              console.error(
                `${indent}Failed to load module for ${cleanPath}:`,
                error,
              );
            }
          } else {
            console.log(
              `${indent}Skipping already processed module: ${cleanPath}`,
            );
          }
        }
      }
    }

    return routesList;
  }

  private isLoadedRouteModule(value: unknown): value is LoadedRouteModule {
    return (
      typeof value === "object" &&
      value !== null &&
      "routes" in value &&
      Array.isArray((value as LoadedRouteModule).routes)
    );
  }

  async getPreRenderRoutes(): Promise<string[]> {
    console.log("Getting pre-render routes...");
    const routes = await this.getAllRoutes();
    const preRenderRoutes = new Set<string>();

    routes.forEach((route) => {
      preRenderRoutes.add(route);
      if (route.includes(":")) {
        preRenderRoutes.add(route.replace(/\/:[^\/]+/g, "/**"));
      }
      if (!route.includes(":") && !route.includes("*")) {
        preRenderRoutes.add(`${route}.html`);
      }
    });

    return Array.from(preRenderRoutes).sort();
  }

  async exportRoutes() {
    if (this.screenSizeService.isBrowser) {
      console.log("Starting route analysis...");
      const startTime = performance.now();

      try {
        const [routes, preRenderRoutes] = await Promise.all([
          this.getAllRoutes(),
          this.getPreRenderRoutes(),
        ]);

        const endTime = performance.now();
        console.log(
          `Route analysis completed in ${(endTime - startTime).toFixed(2)}ms`,
        );
        console.log("Available routes:", routes);
        console.log("Pre-render routes:", preRenderRoutes);
      } catch (error) {
        console.error("Route analysis failed:", error);
      }
    }
  }
}
