<section id="newsletter">
  <h1 class="title med-width">{{ "NEWSLETTER.CallToAction" | translate }}</h1>
  <button
    gaCategory="hp_newsletter_module"
    gaEvent="hp_nav_subscribe_newesletter"
    class="large"
    routerLink="/publisher/newsletter"
  >
    {{ "NEWSLETTER.Subscribe" | translate }}
  </button>
</section>
