import { CanActivateFn, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { inject } from "@angular/core";
import { of } from "rxjs";

export const seriesRedirectGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const router = inject(Router);

  const queryParams = { tags: 'series' };

  return of(router.createUrlTree(['en/books'], { queryParams }));
};
