export const blackList = [
  "_ga",
  "_ga_",
  "_gid",
  "_gat",
  "_hj",
  "__utma",
  "__utmt",
  "__utmb",
  "__utmc",
  "__utmz",
  "__utmv",
  "ki_r",
  "ki_s",
  "ki_t",
  "ki_u",
];
