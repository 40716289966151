<div class="container max-width" gaCategory="footer">
  <div class="column" [ngStyle]="{ justifyContent: 'space-between' }">
    <div class="section">
      <a
        gaEvent="footer_nav_books"
        routerLink="/books"
        routerLinkActive="active"
        class="pointer"
        draggable="false"
        >{{ "GLOBALS.Books" | translate }}</a
      >
      <a
        gaEvent="footer_nav_news"
        routerLink="/news"
        routerLinkActive="active"
        class="pointer"
        draggable="false"
        >{{ "GLOBALS.News" | translate }}</a
      >
    </div>

    <div class="section">
      <!-- <a
        gaEvent="footer_nav_publisher_about_us"
        draggable="false"
        [routerLink]="'/publisher/about-us'"
        class="pointer"
        >{{ "FOOTER.AboutUs" | translate }}</a
      > -->
      <a
        gaEvent="footer_nav_publisher_publish_with_us"
        draggable="false"
        [routerLink]="'/publisher/publish-with-us'"
        class="pointer"
        >{{ "FOOTER.Publish" | translate }}</a
      >
      <a
        gaEvent="footer_nav_publisher_press"
        draggable="false"
        [routerLink]="'/publisher/press'"
        class="pointer"
        >{{ "FOOTER.Press" | translate }}</a
      >
      <a
        gaEvent="footer_nav_publisher_sales"
        draggable="false"
        [routerLink]="'/publisher/sales'"
        class="pointer"
        >{{ "FOOTER.Sales" | translate }}</a
      >
      <a
        gaEvent="footer_nav_publisher_catalog"
        draggable="false"
        [routerLink]="'/publisher/catalog'"
        class="pointer"
        >{{ "FOOTER.Catalog" | translate }}</a
      >
      <a
        gaEvent="footer_nav_publisher_open_access"
        draggable="false"
        [routerLink]="'/publisher/open-access'"
        class="pointer"
        >{{ "FOOTER.OpenAccess" | translate }}</a
      >
    </div>
  </div>

  <div
    class="column"
    [ngStyle]="{ flexGrow: 3, justifyContent: 'space-between' }"
  >
    <div class="section">
      <div id="social-media" class="section">
        <a
          gaEvent="footer_nav_publisher_newsletter"
          draggable="false"
          [routerLink]="'/publisher/newsletter'"
          >{{ "GLOBALS.Newsletter" | translate }}</a
        >
        <a
          gaEvent="footer_nav_facebook"
          draggable="false"
          [href]="nav.getFacebookLink()"
          target="_blank"
          >Facebook</a
        >
        <a
          gaEvent="footer_nav_instagram"
          draggable="false"
          [href]="nav.getInstagramLink()"
          target="_blank"
          >Instagram</a
        >
      </div>
    </div>
    <div class="section">
      <a
        gaEvent="footer_nav_imprint"
        draggable="false"
        [routerLink]="'/imprint'"
        class="pointer"
        >{{ "FOOTER.Imprint" | translate }}</a
      >
      <a
        gaEvent="footer_nav_privacy_policy"
        draggable="false"
        target="_blank"
        [href]="nav.getPrivacyPolicyLink()"
        class="pointer"
        >{{ "FOOTER.Privacy" | translate }}</a
      >
      <a
        gaEvent="footer_nav_cookie_policy"
        draggable="false"
        [routerLink]="'/cookie-policy'"
        class="pointer"
        >{{ "FOOTER.Cookies" | translate }}</a
      >
      <!-- <a
        draggable="false"
        target="_blank"
        [href]="getCookiePolicyLink()"
        class="pointer"
        >{{ "FOOTER.Cookies" | translate }}</a
      > -->
      <a
        gaEvent="footer_nav_terms"
        draggable="false"
        target="_blank"
        [href]="nav.getTermsAndConditionsLink()"
        class="pointer"
        >{{ "FOOTER.Terms" | translate }}</a
      >
    </div>
  </div>

  <div class="column">
    <div class="section">
      <div
        gaEvent="footer_language_switcher"
        id="language-switcher"
        (click)="languageService.switchLanguage()"
        class="pointer"
      >
        <span [class.active]="languageService.isCurrentLanguage('de')">DE</span>
        <span [class.active]="languageService.isCurrentLanguage('en')">EN</span>
      </div>

      <!-- <div id="currency-switcher" class="pointer">
        <span
          [class.active]="shoppingCartService.currency == 'EUR'"
          (click)="shoppingCartService.setCurrencyAndSaveCart('EUR')"
          >EUR</span
        >
        /
        <span
          [class.active]="shoppingCartService.currency == 'GBP'"
          (click)="shoppingCartService.setCurrencyAndSaveCart('GBP')"
          >GBP</span
        >
        /
        <span
          [class.active]="shoppingCartService.currency == 'USD'"
          (click)="shoppingCartService.setCurrencyAndSaveCart('USD')"
          >USD</span
        >
      </div> -->
    </div>

    <div id="address" class="section">
      <div>{{ "FOOTER.ADDRESS" | translate }}</div>
      <a
        gaEvent="footer_click_email"
        href="mailto:info@jovis.de"
        class="pointer"
        draggable="false"
        >info&#64;jovis.de</a
      >
      <div [innerHTML]="'FOOTER.Contact' | translate "></div>
    </div>

    <div>
      <span>© {{year}}&nbsp;{{ "FOOTER.Copyright" | translate }}</span>
    </div>
  </div>
</div>
