import { Injectable, signal } from "@angular/core";
import { HomePageModule } from "../models/home-page-module.model";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class HomepageModulesService {
  // fetch the module ids from the backend
  // GET /api/homepage-modules

  public modules = signal<HomePageModule[]>([]);

  private errorModule: HomePageModule = {
    id: "error",
    scrollType: "normal",
    navbarColor: {
      background: "white",
      text: "black",
    },
    data: "",
  };

  constructor() {}

  async fetchModules(): Promise<HomePageModule[]> {
    const timestampSeconds = Math.floor(Date.now() / 1000);
    const url =
      environment.wpHost.replace("/wp-json", "") +
      "/wp-content/modules/modules.json?ts=" +
      timestampSeconds;
    const response = await fetch(url);
    const json = await response.json();

    if (json.length === 0) {
      return [this.errorModule];
    }

    this.modules.set(json as HomePageModule[]);
    return json as HomePageModule[];
  }
}
