import {
  trigger,
  state,
  style,
  transition,
  animate,
} from "@angular/animations";
import {
  AfterContentInit,
  Component,
  OnDestroy,
  inject,
  effect,
} from "@angular/core";
import { GoogleAnalyticsService } from "ngx-google-analytics";
import { LanguageService } from "src/app/core/services/language.service";
import { NavbarColor } from "src/app/models/shared-models/navbar-color";
import { NavbarService } from "src/app/services/navbar.service";
import { SearchService } from "src/app/services/search.service";
import { ShoppingCartService } from "src/app/services/shopping-cart.service";
import { NavigationService } from "src/app/shared/navigation.service";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { InlineSVGModule } from "ng-inline-svg-w";
import { NavbarColorDirective } from "src/app/shared/navbar-color.directive";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    InlineSVGModule,
    NavbarColorDirective,
  ],
  animations: [
    trigger("slideInOut", [
      state("in", style({ transform: "translateX(0)", display: "flex" })),
      state("out", style({ transform: "translateX(100%)", display: "none" })),
      transition("out => in", [
        style({ display: "flex" }),
        animate("120ms ease-out"),
      ]),
      transition("in => out", [
        animate(
          "120ms ease-in",
          style({ transform: "translateX(100%)", display: "none" }),
        ),
      ]),
    ]),
  ],
})
export class NavbarComponent implements OnDestroy, AfterContentInit {
  languageService = inject(LanguageService);
  private navbarService = inject(NavbarService);
  nav = inject(NavigationService);
  cart = inject(ShoppingCartService);
  private searchService = inject(SearchService);
  private $gaService = inject(GoogleAnalyticsService);

  defaultColor: NavbarColor = {
    background: "transparent",
    text: "transparent",
  };
  backgroundColor = "transparent";
  textColor = "transparent";
  showBurgerMenu = false;

  constructor() {
    effect(() => {
      const color = this.navbarService.color();
      this.backgroundColor = color.background;
      this.textColor = color.text;
    });
  }

  get currentLang(): string {
    return this.languageService.currentLanguage();
  }

  switchLanguage() {
    this.languageService.switchLanguage();
    this.$gaService.event("header_language_switch", "click", this.currentLang);
  }

  ngAfterContentInit(): void {
    this.navbarService.initObserver();
  }

  ngOnDestroy(): void {}

  goToSearch(showSearchBox: boolean) {
    this.showBurgerMenu = false;
    this.nav.goToSearch(showSearchBox);
  }

  openBooksPage() {
    this.showBurgerMenu = false;
    this.searchService.showSearchBox.next(false);
  }

  openBurgerMenu() {
    this.$gaService.event("burger_menu_open", "burger_menu");
    this.showBurgerMenu = true;
  }

  closeBurgerMenu() {
    this.$gaService.event("burger_menu_close", "burger_menu");
    this.showBurgerMenu = false;
  }
}
