import { inject } from "@angular/core";
import {
  CanActivateFn,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from "@angular/router";

export const redirectEbooksGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): boolean | UrlTree => {
  const router = inject(Router);
  const urlSegments = state.url.split("/");
  const langSegment = urlSegments[1];

  if (urlSegments.includes("e-books")) {
    return router.createUrlTree([`/${langSegment}/search`], {
      queryParams: { tags: "eBook" },
    });
  }

  return true;
};
