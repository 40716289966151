import { Component, Input, OnInit, inject } from "@angular/core";

import { RouterModule } from "@angular/router";
import { CatalogService } from "@app/services/catalog.service";
import { CollectionService } from "@app/services/collection.service";
import { TranslateModule, TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-catalog",
  templateUrl: "./catalog.component.html",
  styleUrls: ["./catalog.component.scss"],
  standalone: true,
  imports: [RouterModule, TranslateModule],
})
export class CatalogComponent implements OnInit {
  private translate = inject(TranslateService);
  catalogService = inject(CatalogService);

  @Input() module!: any;

  constructor() {}

  async ngOnInit(): Promise<void> {
    await this.catalogService.loadContent("catalog");
  }

  async downloadCatalog() {
    try {
      const url = this.catalogService.catalogUrl();
      const response = await fetch(url);

      if (!response.ok) {
        throw new Error("File not found");
      }

      const blob = await response.blob();
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = this.catalogService.catalogFileName();
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error("Error downloading catalog:", error);
      // Could show an error message to user here
      alert(this.translate.instant("CATALOG.ErrorDownloading"));
    }
  }
}
