import { Component, OnInit, PLATFORM_ID, inject } from "@angular/core";
import { JovisTitleService } from "./core/services/jovis-title.service";
import { CookieToolComponent } from "./components/cookie-tool/cookie-tool.component";
import { FooterComponent } from "./components/footer/footer.component";
import { RouterOutlet } from "@angular/router";
import { NavbarComponent } from "./components/navbar/navbar.component";
import { ScreenSizeService } from "./shared/screen-size.service";
import { ExportRoutesService } from "./services/export-routes.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  standalone: true,
  imports: [
    NavbarComponent,
    RouterOutlet,
    FooterComponent,
    CookieToolComponent,
  ],
})
export class AppComponent implements OnInit {
  private jovisTitle = inject(JovisTitleService);
  screenSizeService = inject(ScreenSizeService);
  private exportRoutesService = inject(ExportRoutesService);

  title = "jovis";

  constructor() {
    this.title = this.jovisTitle.setTitle();
  }

  async ngOnInit(): Promise<void> {
    // this.exportRoutesService.exportRoutes();
  }
}
