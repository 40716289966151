<section
  id="thema-codes"
  [style.background]="page?.acf?.background_color"
  [style.color]="module.navbarColor?.text"
>
  @if (page?.featuredImage) {
    <img
      class="header-background sm-up"
      [image]="page!.featuredImage!"
      [attr.alt]="safeAlt()"
    />
  }
  @if (page?.featuredImageMobile || page?.featuredImage) {
    <img
      class="header-background sm-down"
      [image]="page!.featuredImageMobile ?? page!.featuredImage!"
      [attr.alt]="safeAlt()"
    />
  }

  <div class="container max-width">
    <div class="subjects-container" gaCategory="hp_thema_codes">
      @for (subject of subjects; track subject; let i = $index) {
        <h1 class="subject title left" [fadeOnScroll]="true">
          <a
            [gaEvent]="
              'hp_search_subject_' + ('THEMA_CODE.' + subject | translate)
            "
            (click)="setSubjectSelected(subject)"
            class="pointer"
            [tabindex]="i"
            [ariaAtomic]="true"
            >{{ "THEMA_CODE." + subject | translate }}</a
          >
          @if (i + 1 < subjects.length) {
            <span>,&nbsp;</span>
          }
        </h1>
      }
    </div>
    @if (creditLineText) {
      <div class="thematic-credit-container" [fadeOnScroll]="true">
        <span class="mono small" [style.color]="creditLineTextColor">
          <span>{{ creditLineText }}&#8203;</span>
          <span class="credit-line-details">
            <span>&nbsp;{{ "HP_MODULE.from" | translate }}&nbsp;</span>
            <a [href]="creditLink"
              ><strong>{{ creditLineLinkText }}</strong
              >&nbsp;-&gt;</a
            >
          </span>
        </span>
      </div>
    }
  </div>
</section>
